import QLOLogoMark from '../assets/QLOLogoMark.png';

export default function Footer(){
    return(
        <div className="bg-black flex flex-col justify-center items-center p-5">
            <img 
                src={QLOLogoMark} 
                alt='QLO Logo'
                className='w-96'/>
            <h1 className='text-white text-xs'>&copy; 2023 Quilaton Law Office. All Rights Reserved.</h1>
            <div className='mt-5 flex gap-5'>
                <a
                    href='https://facebook.com/quilatonlawoffice'
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white text-xs py-3 px-5 border rounded-full hover:text-black hover:bg-white duration-300 ease-in-out"
                >f</a>
                <a
                    href='https://linkedin.com/in/atty-abelardo-quilaton-jr-5a3b25248/'
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white text-xs py-3 px-5 border rounded-full hover:text-black hover:bg-white duration-300 ease-in-out"
                >in</a>
            </div>
        </div>
    )
}