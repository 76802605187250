import React, { useState, useEffect } from 'react';
import CustomMargin from '../../../components/custom-margin';
import QuotationMark from '../../../assets/quotation-mark.svg';
import { chunkArray } from '../../../components/functions';
import { truncateText } from '../../../components/functions';
import SubmitTestimonial from './submit-testimonial';
import axios from 'axios';
import { PROXY } from '../../../components/constants';


export default function TestimonialCard() {
    const [startNum, setStartNum] = useState(6);
    const [testimonial, setTestimonial] = useState([]); 
    const chunkTestimonial = chunkArray(testimonial.slice(0, startNum), 3);
    const [expandedIndexes, setExpandedIndexes] = useState({});
    const [feedbackSubmit, setFeedbackSubmit] = useState(false);

    const handleExpandClick = (arrayIndex, index) => {
        setExpandedIndexes(prevState => ({
            ...prevState,
            [arrayIndex]: prevState[arrayIndex] === index ? null : index
        }));
    };

    const handleClickMore = (num) => {
        setStartNum((prev) => prev + num);
    }

    const handleFeedback = () => {
        setFeedbackSubmit(!feedbackSubmit);
    }

    useEffect(() => {
        fetchFeedback();
    }, [])

    const fetchFeedback = () => {
        axios.get(`${PROXY}/fetch_approved_feedback`)
        .then(res => {
            setTestimonial(res.data);
        })
        .catch(err => console.error(err));
    }


    return (
        <div className='max-w-7xl m-auto'>
            <CustomMargin className='flex flex-col m-auto w-full p-5'>
                {chunkTestimonial.map((arrayData, arrayIndex) => (
                    <div key={arrayIndex} className='flex sm:flex-col lg:flex-row gap-5'>
                        {arrayData.map((testimonial, index) => {
                            const { truncated, isTruncated } = truncateText(testimonial.details, 40);
                            const isExpanded = expandedIndexes[arrayIndex] === index;

                            return (
                                <div
                                    key={index}
                                    className={`flex lg:w-1/3 sm:mt-5 gap-2 w-full ${isExpanded ? 'h-auto' : ''} transition-all duration-300 ease-in-out`}
                                >
                                    <img alt='quotation mark' src={QuotationMark} className='w-7 h-7 scale-x-[-1]' />
                                    <div className='flex flex-col'>
                                        <h2 className='text-2xl font-fontMontserrat'>{testimonial.title}</h2>
                                        <p>{isExpanded ? testimonial.details : truncated}</p>
                                        {isTruncated && !isExpanded && (
                                            <div
                                                className='hover:text-white duration-300 ease-in-out cursor-pointer'
                                                onClick={() => handleExpandClick(arrayIndex, index)}
                                            >
                                                [+]
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ))}
            </CustomMargin>
            <div className='flex flex-col m-auto w-full'>
                <div className='sm:p-5'>
                    <button 
                        className={`flex items-center mt-10 hover:space-x-2 transition-all duration-300 ease-in-out ${testimonial.length < startNum ? 'hidden' : ''}`}
                        onClick={() => handleClickMore(3)}
                    >
                        <p className=''>MORE TESTIMONIAL</p>
                        <small className='text-xs'>&#65125;&#65125;</small>
                    </button>
                </div>
                <div className='border-t border-t-black p-5 mt-10'>
                    <p className='font-bold text-xl'>Have You Worked With Us?</p>
                    <button
                        onClick={() => handleFeedback()}
                        className='mt-5 border border-black px-7 py-5 hover:text-white hover:bg-black transition-all duration-300 ease-in-out'>Leave Us Feedback</button>
                </div>
            </div>
            {feedbackSubmit && (
                <SubmitTestimonial/>
            )}
        </div>
    );
}
