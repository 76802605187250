import Photo2 from '../.././assets/photo2.jpg';
import Photo3 from '../.././assets/carousel-home/2023-07-16 14_52_53.742+0800.jpg';
import CustomMargin from '../../components/custom-margin';

export default function Careers(){
    return(
        <CustomMargin>
            <div className='flex flex-col justify-center items-center p-10'>
                <div className='w-auto gap-2 sm:hidden lg:flex'>
                    <img alt='QLO' src={Photo3} className='w-1/2'/>
                    <img alt='QLO' src={Photo2} className='w-1/2'/>
                </div>
                <p className='text-2xl font-fontMontserrat'>We are looking for dedicated, development-oriented, performance-based, pro-active, and solutions-focused individuals who have the grit and passion to serve the people through the legal services that we offer.</p>
                <p className='p-5'>NO CAREERS ARE CURRENTLY OFFERED AS OF THE MOMENT.</p>
            </div>
        </CustomMargin>
    );
}