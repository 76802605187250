import { useState, useEffect } from 'react';

export default function AlertComponent({ message, duration = 3000 }) {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
        }, duration);
        return () => clearTimeout(timer);
    }, [duration]);

    return (
        <div className={`fixed inset-0 flex items-center justify-center ${visible ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition-opacity duration-500`}>
            <div className={`transform ${visible ? 'translate-y-0' : '-translate-y-full'} transition-transform duration-500 bg-black text-white p-4 rounded-md shadow-lg`}>
                {message}
            </div>
        </div>
    );
}
