import { BrowserRouter, Route, Routes } from "react-router-dom";
import TopNav from "./components/top-nav";
import ScrolledNav from "./components/scrolled-nav";
import Home from './pages/home/home';
import AboutUs from './pages/about-us/about-us';
import Practices from './pages/practices/practices';
import Feedback from './pages/feedback/feedback';
import Careers from './pages/careers/careers';
import ContactUs from './pages/contact-us/contact-us';
import Footer from "./components/footer";
import PostDetails from "./pages/practices/component/post-details";

function App() {
  return (
    <BrowserRouter>
      <div className="font-fontLato">
        <ScrolledNav/>
        <TopNav />
          <Routes>
            <Route path='/' element={<Home />}></Route>
            <Route path='/about/' element={<AboutUs />}></Route>
            <Route path='/practices/' element={<Practices />}></Route>
            <Route path='/careers/' element={<Careers />}></Route>
            <Route path='/feedback/' element={<Feedback />}></Route>
            <Route path='/contact/' element={<ContactUs />}></Route>
            <Route path='/practices/posts/:title' element={<PostDetails />}></Route>
          </Routes>
        <Footer/>
      </div>
    </BrowserRouter>
  );
}

export default App;
