import CustomMargin from "../../../components/custom-margin";

export default function MissionSection(){
    return (
        <CustomMargin>
            <div className="flex text-base lg:flex-col xxl:flex-row xxl:items-center">
                {/* <Carousel
                    autoPlay={true}
                    showArrows={false}
                    showIndicators={false}
                    showThumbs={false}
                    emulateTouch={false}
                    swipeable={false}
                    infiniteLoop={true}
                    stopOnHover={false}
                    transitionTime={1000}
                    interval={5000}
                    showStatus={false}
                    className="w-4/5 sm:hidden lg:w-full lg:flex"
                >
                    <img 
                        alt="QLO"
                        src={Img1}
                        className="object-cover"
                    />
                    <img 
                        alt="QLO"
                        src={Img2}
                        className="object-cover"
                    /> 
                    <img 
                        alt="QLO"
                        src={Img3}
                        className=" object-cover"
                    /> 
                    <img 
                        alt="QLO"
                        src={Img4}
                        className=" object-cover"
                    />  
                </Carousel> */}
                <div className="p-5 xxl:pt-0 lg:w-full sm:text-center lg:text-center xxl:text-left">
                    <div className="mt-5 xxl:mt-0">
                        <h1 className="font-fontMontserrat text-4xl lg:text-6xl xxl:text-5xl">MISSION</h1>
                        <p className="text-xl">Quilaton Law Office aims to provide affordable and seamless legal services to our clients by effectively combining our highly diverse and specialized legal skills and passion to serve the people — locally and globally.</p>
                    </div>
                    <div className="mt-10">
                        <h1 className="font-fontMontserrat text-4xl lg:text-6xl xxl:text-5xl">VISION</h1>
                        <p className="text-xl">The Quilaton Law Office envisions a future where our law firm is among the superior law firms in the country capable of providing timely but top-notch services to our growing clients.</p>
                        <p className="text-xl">Our law firm will continuously be the avenue for clients seeking peace of mind in their legal concerns at an affordable rate.</p>
                    </div>
                    <div className="mt-10">
                        <h1 className="font-fontMontserrat text-4xl lg:text-6xl xxl:text-5xl">CORE VALUES</h1>
                        <p className="text-xl">Our core values include collaboration, compassion and empathy, efficiency, integrity, innovation and sustainability, and justice.</p>
                    </div>
                </div>
            </div>
        </CustomMargin>
    )
}