import CustomMargin from "../../../components/custom-margin";
import { useState } from 'react';
import AlertComponent from '../../../components/alert-component';

export default function ContactForm() {
    const [value, setValue] = useState({
        name: '',
        gender: '',
        birthdate: '',
        age: '',
        address: '',
        civilStatus: '',
        citizenship: '',
        contactNumber: '',
        email: '',
        purposeOfConsultation: '',
        briefNarrationOfTheCase: '',
    });

    const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    const phonePattern = /^(?:\+63|0)9[0-9]{9}$/;
    const [alertMessage, setAlertMessage] = useState('');
    const [isAlert, setAlert] = useState(false);

    const areAllValuesFilled = (obj) => {
        return Object.values(obj).every(value => value.trim() !== '');
    };

    const showAlert = (message) => {
        setAlertMessage(message);
        setAlert(true);
        setTimeout(() => setAlert(false), 2000);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!emailPattern.test(value.email)  ) {
            showAlert("Invalid email format. Please use email@domain.com.");
        } else if (!phonePattern.test(value.contactNumber)) {
            showAlert("Enter a valid Philippine mobile number.");
        } else if (!areAllValuesFilled(value)) {
            showAlert("Please fill out all the fields.");
        } else {
            const formData = new FormData(e.target);

            formData.append("access_key", "ab73a0b8-df2d-4209-bfbc-4bdccb6aa090");

            const response = await fetch("https://api.web3forms.com/submit", {
                method: "POST",
                body: formData
            });

            const data = await response.json();

            if (data.success) {
                e.target.reset();
                showAlert("Your message has been sent. Please wait for a response.")
            } else {
                console.log("Error", data);
            }
        }
    };

    return (
        <CustomMargin className="w-4/5">
            {isAlert && (
                <AlertComponent message={alertMessage} duration={2000} />
            )}
            <form onSubmit={handleSubmit}>
                <div className="flex sm:flex-col lg:flex-row justify-center items-center gap-5">
                    <div className="sm:w-full lg:w-1/2">
                        <div className="flex flex-col mt-3">
                            <label>Name <small>(First Name, Middle Initial, Last Name, Suffix)</small></label>
                            <input 
                                name="name"
                                type="text" 
                                className="border border-black p-1"
                                value={value.name}
                                onChange={(e) => setValue({...value, name: e.target.value})}
                            />
                        </div>
                        <div className="flex flex-col mt-3">
                            <label>Gender <small>(Male, Female, Other)</small></label>
                            <input
                                name="gender"                                
                                type="text" 
                                className="border border-black p-1 "
                                value={value.gender}
                                onChange={(e) => setValue({...value, gender: e.target.value})}
                            />
                        </div>
                        <div className="flex flex-col mt-3">
                            <label>Birthdate <small>(DD/MM/YYYY)</small></label>
                            <input
                                name="birthdate"
                                type="text" 
                                className="border border-black p-1"
                                value={value.birthdate}
                                onChange={(e) => setValue({...value, birthdate: e.target.value})}
                            />
                        </div>
                        <div className="flex flex-col mt-3">
                            <label>Age</label>
                            <input
                                name="age" 
                                type="number" 
                                className="border border-black p-1"
                                value={value.age}
                                onChange={(e) => setValue({...value, age: e.target.value})}
                            />
                        </div>
                        <div className="flex flex-col mt-3">
                            <label>Address <small>(Street Address, City, Postal Code, Country)</small></label>
                            <input
                                name="address" 
                                type="text" 
                                className="border border-black p-1"
                                value={value.address}
                                onChange={(e) => setValue({...value, address: e.target.value})}
                            />
                        </div>
                    </div>
                    <div className="sm:w-full lg:w-1/2">
                        <div className="flex flex-col mt-3">
                            <label>Civil Status <small>(Single, Married, Widowed, Separated)</small></label>
                            <input
                                name="civilStatus" 
                                type="text" 
                                className="border border-black p-1"
                                value={value.civilStatus}
                                onChange={(e) => setValue({...value, civilStatus: e.target.value})}
                            />
                        </div>
                        <div className="flex flex-col mt-3">
                            <label>Citizenship</label>
                            <input
                                name="citizenship" 
                                type="text" 
                                className="border border-black p-1"
                                value={value.citizenship}
                                onChange={(e) => setValue({...value, citizenship: e.target.value})}
                            />
                        </div>
                        <div className="flex flex-col mt-3">
                            <label>Contact Number <small>(Use Philippines contact number. +63 or 09)</small></label>
                            <input
                                name="contactNumber" 
                                type="text" 
                                className="border border-black p-1"
                                value={value.contactNumber}
                                onChange={(e) => setValue({...value, contactNumber: e.target.value})}
                            />
                        </div>
                        <div className="flex flex-col mt-3">
                            <label>Email <small>(Use valid email address)</small></label>
                            <input
                                name="email" 
                                type="email" 
                                className="border border-black p-1"
                                value={value.email}
                                onChange={(e) => setValue({...value, email: e.target.value})}
                            />
                        </div>
                        <div className="flex flex-col mt-3">
                            <label>Purpose of Consultation</label>
                            <input
                                name="purposeOfConsultation"
                                type="text" 
                                className="border border-black p-1"
                                value={value.purposeOfConsultation}
                                onChange={(e) => setValue({...value, purposeOfConsultation: e.target.value})}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex flex-col mt-3">
                        <label>Brief Narration of the Case</label>
                        <textarea
                            name="briefNarrationOfTheCase" 
                            type="text"
                            rows={3}
                            className="border border-black p-1"
                            value={value.briefNarrationOfTheCase}
                            onChange={(e) => setValue({...value, briefNarrationOfTheCase: e.target.value})}
                        />
                    </div>
                </div>
                <button type="submit" className="border border-black px-14 py-5 mt-5 mb-5 bg-black text-white hover:bg-white hover:text-black transition-all duration-300 ease-in-out">SUBMIT</button>
            </form>
        </CustomMargin>
    );
}
