import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CustomMargin from '../../../components/custom-margin';
import { chunkArray } from '../../../components/functions';
import { truncateText } from '../../../components/functions';
import axios from 'axios';
import { PROXY } from '../../../components/constants';
import { formatTitleForURL } from '../../../components/functions';

export default function CardPost({ onLatestUpdate }) {
    const [visiblePost, setVisiblePost] = useState(6);
    const [posts, setPosts] = useState([]);
    const [hideButton, setHideButton] = useState(false);

    const chunkedPosts = chunkArray(posts.slice(0, visiblePost), 3);

    const clickMore = (add) => {
        setVisiblePost((prev) => prev + add);
    };

    useEffect(() => {
        fetchPosts();
    }, []);

    useEffect(() => {
        if (visiblePost >= posts.length) {
            setHideButton(true);
        } else {
            setHideButton(false);
        }
    }, [visiblePost, posts]);

    const fetchPosts = () => {
        axios.get(`${PROXY}/fetch_posts`)
        .then(res => {
            const sortedPosts = res.data.sort((a, b) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                return dateB - dateA;
            });
            setPosts(sortedPosts)
            if(sortedPosts.length > 0) {
                onLatestUpdate(sortedPosts[0].date);
            }
        })
        .catch(err => console.error(err));
    }

    return (
        <div className='max-w-7xl'>
            <CustomMargin className='flex flex-col gap-5 p-5'>
                {chunkedPosts.map((postChunk, rowIndex) => (
                    <div key={rowIndex} className="flex sm:flex-col lg:flex-row gap-5">
                        {postChunk.map((post, postIndex) => {
                            const { truncated, isTruncated } = truncateText(post.details, 30);
                            return (
                                <div key={postIndex} className="flex flex-col border-b border-b-gray-400 mb-5 sm:w-full lg:w-1/3">
                                    <img alt="Post" src={`${PROXY}/uploads/images/${post.image}`} className="bg-black h-96 w-full object-cover" />
                                    <div className="flex flex-col justify-start mt-3">
                                        <small className="text-slate-400">{post.date} | Quilaton Law Office</small>
                                        <h2 className="font-bold text-3xl">{post.title}</h2>
                                        <p>{truncated}</p>
                                        <Link to={`/practices/posts/${formatTitleForURL(post.title)}/`}>
                                            Read more
                                        </Link>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ))}
            </CustomMargin>
            <div className='lg:p-5 sm:p-5'>
                <button
                    onClick={() => clickMore(3)}
                    className={`mb-10 border border-black px-10 py-7 text-l hover:bg-black hover:text-white transition-all duration-300 ease-in-out ${hideButton ? 'hidden' : ''}`}
                >
                    MORE POSTS
                </button>
            </div>
        </div>
    );
}
