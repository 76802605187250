import { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axios from 'axios';
import { PROXY } from '../../../components/constants'
import { Link } from "react-router-dom";
import { truncateText } from "../../../components/functions";
import { formatTitleForURL } from "../../../components/functions";

export default function MotionCard() {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        fetchPosts();
    }, [])

    const fetchPosts = () => {
        axios.get(`${PROXY}/fetch_posts`)
        .then(res => {
            const sortedPosts = res.data.sort((a, b) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                return dateB - dateA;
            });
            setPosts(sortedPosts)
        })
        .catch(err => console.error(err));
    }

    return (
        <Carousel 
            showThumbs={false} 
            showStatus={false}
            showIndicators={false}
            transitionTime={1500} 
            infiniteLoop 
            emulateTouch 
            stopOnHover
        >
            <div className="flex sm:flex-col lg:flex-row">
                {posts.slice(0, 2).map((motion, index) => {
                     const { truncated, isTruncated } = truncateText(motion.details, 30);

                     return (
                         <div key={index} className="w-full p-2 flex sm:flex-col border border-black">
                         <img alt="Post" src={`${PROXY}/uploads/images/${motion.image}`} className="bg-black h-72 w-72 object-cover" />
                         <div className="text-black p-2 text-left">
                             <h1 className="font-fontMontserrat text-3xl">{motion.title}</h1>
                             <h2 className="text-m text-gray-400">{motion.date}</h2>
                             <h2 className="text-m">Quilaton Law Office</h2>
                             <p className="mt-5 mb-5">{truncated}</p>
                             <Link to={`/practices/posts/${formatTitleForURL(motion.title)}/`} className='hover:underline'>
                                 Read more
                             </Link>
                         </div>
                     </div>
                     )
                })}
            </div>
            <div className="flex sm:flex-col lg:flex-row">
                {posts.slice(2, 4).map((motion, index) => {
                    const { truncated, isTruncated } = truncateText(motion.details, 30);

                    return (
                        <div key={index} className="w-full p-2 flex sm:flex-col border border-black">
                        <img alt="Post" src={`${PROXY}/uploads/images/${motion.image}`} className="bg-black h-72 w-72 object-cover" />
                        <div className="text-black p-2 text-left">
                            <h1 className="font-fontMontserrat text-3xl">{motion.title}</h1>
                            <h2 className="text-m text-gray-400">{motion.date}</h2>
                            <h2 className="text-m">Quilaton Law Office</h2>
                            <p className="mt-5 mb-5">{truncated}</p>
                            <Link to={`/practices/posts/${formatTitleForURL(motion.title)}/`} className='hover:underline'>
                                Read more
                            </Link>
                        </div>
                    </div>
                    )
                })}
            </div>
        </Carousel>
    );
}
