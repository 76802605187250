import ContactForm from "./components/contact-form";
import DetailsInfo from "./components/details-info";

export default function ContactUs(){
    return(
        <div className="mb-10">
            <p className="flex justify-center items-center mt-5 text-xl mb-5 lg:text-center sm:text-center sm:px-5">We aim to respond to all inquiries within 24 hours. Your satisfaction is our priority, and we appreciate your interest in our services.</p>
            <ContactForm/>
            <DetailsInfo/>
        </div>
    );
}