import { Link } from "react-router-dom"

export default function NavHome(){
    return(
        <div className="relative">
            <div className="absolute inset-0 transform translate-x-4 translate-y-4 flex-col justify-center text-center text-black filter blur-md pointer-events-none">
                <h1 className="font-bold sm:text-5xl">QUILATON LAW OFFICE</h1>
                <h1 className="text-6xl mt-5 sm:text-3xl">Promptness meets competence</h1>
                <p className="text-base m-auto mt-5 max-w-3xl">An all-specialization and independent law firm in Cebu City, Philippines that offers a wide range of legal services. Our law firm places a premium on competence, integrity, and professionalism to ensure maximum satisfaction among our clients. With years of service in the legal industry, we continuously and passionately expand our presence both locally and internationally.</p>
            </div>
            <div className="relative flex-col justify-center text-center text-white mt-56 lg:mt-48">
                <h1 className="font-bold sm:text-5xl lg:text-7xl xxl:text-8xl">QUILATON LAW OFFICE</h1>
                <h1 className="text-6xl mt-5 sm:text-3xl lg:text-5xl xxl:text-6xl">Promptness meets competence</h1>
                <p className="text-base m-auto mt-5 max-w-3xl ">An all-specialization and independent law firm in Cebu City, Philippines that offers a wide range of legal services. Our law firm places a premium on competence, integrity, and professionalism to ensure maximum satisfaction among our clients. With years of service in the legal industry, we continuously and passionately expand our presence both locally and internationally.</p>
            </div>
            <div className="flex justify-center items-center mt-10 gap-5">
                <a
                    href='https://facebook.com/quilatonlawoffice'
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white lg:text-base sm:text-xs py-5 px-7 border hover:text-black hover:bg-white duration-300 ease-in-out"
                >f</a>
                <a
                    href='https://linkedin.com/in/atty-abelardo-quilaton-jr-5a3b25248/'
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white lg:text-base sm:text-xs py-5 px-7 border rounded-full hover:text-black hover:bg-white duration-300 ease-in-out"
                >in</a>
                <Link 
                    to='/contact/' 
                    className="lg:text-base sm:text-xs bg-white text-black border sm:px-5 py-5 px-7 hover:text-white hover:bg-black hover:border-black duration-300 ease-in-out"
                >
                    Contact Us
                </Link>
            </div>
        </div>
    )
}