import NavLinks from "./nav-links";
import QLOLogo from '../assets/QLOLogoMark.png';
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import CustomMargin from "./custom-margin";
import NavHome from "../pages/home/components/nav-home";
import { Link } from 'react-router-dom';

export default function TopNav(){
    let location = useLocation();
    const { pathname } = location;
    const [scrolled, setScrolled] = useState(false);

    const path = {
        '/':'Home',
        '/about/':'About us',
        '/practices/':'Practices',
        '/careers/':'Careers',
        '/feedback/':'Feedback',
        '/contact/':'Contact Us'
    };

    const title = path[pathname] || 'Quilaton Law Office';
    const minHeight = pathname === '/' ? 'min-h-screen' : 'min-h-auto';

    //This will change the document title every clicks on the navigation links
    useEffect(() => {
        document.title = title + ' | Quilaton Law Office';

        const handleScroll = () => {
            if(window.scrollY > 0) {
                setScrolled(true);
            }else{
                setScrolled(false);
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [pathname, title])

    return(
        <div className={`bg-heroPattern bg-cover ${minHeight}`}>
            <CustomMargin>
                <div className={`flex p-2 items-center justify-between ${scrolled ? 'bg-black opacity-0':''} transition-all duration-300 ease-in-out`}>
                    <Link
                        to='/' 
                    >
                        <img 
                            src={QLOLogo} 
                            alt="QLO Logo"
                            className="w-96 sm:w-60 md:w-72" />
                    </Link>
                    <div>
                        <NavLinks/>
                    </div>
                </div>
                <div>
                    {title === 'Home' ? (
                        <CustomMargin className="p-5">
                            <NavHome/>
                        </CustomMargin>
                    ): (
                        <h1 className="text-center text-white text-bold mt-10 pb-10 text-7xl font-fontMontserrat sm:text-5xl">{title}</h1>
                    )}
                </div>
            </CustomMargin>
        </div>
    )
} 