import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { PROXY } from '../../../components/constants';
import { reverseFormatTitle, formatTitleForURL } from '../../../components/functions';
import { Link } from "react-router-dom";
import ArrowRight from '../../../assets/arrow-long-right.svg';
import ArrowLeft from '../../../assets/arrow-long-left.svg';

export default function PostDetails() {
    const pathname = window.location.href;
    const { title } = useParams();
    const [post, setPost] = useState(null);
    const [recent, setRecent] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (title) {
            const originalTitle = reverseFormatTitle(title);
            fetchPosts(originalTitle);
        }
        fetchRecent();
    }, [title]);

    const fetchPosts = (originalTitle) => {
        axios.get(`${PROXY}/fetch_posts_detail`, {
            params: { title: originalTitle }
        })
        .then(res => {
            setPost(res.data); 
            setLoading(false);
        })
        .catch(err => {
            console.error('Failed to fetch post:', err);
            setError('Failed to fetch post details');
            setLoading(false);
        });
    };

    const fetchRecent = () => {
        axios.get(`${PROXY}/fetch_posts`)
        .then(res => {
            const sortedPosts = res.data.sort((a, b) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                return dateB - dateA;
            });
            setRecent(sortedPosts);
        })
        .catch(err => {
            console.error('Failed to fetch recent posts:', err);
        });
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;
    if (!post || post.length === 0) return <div>No post found</div>;

    // Extract the URLs and titles for the buttons
    const currentPostIndex = recent.findIndex(rec => rec.title === post[0]?.title);

    const previousPost = currentPostIndex > 0 ? recent[currentPostIndex - 1] : null;
    const nextPost = currentPostIndex < recent.length - 1 ? recent[currentPostIndex + 1] : null;

    return (
        <div className='max-w-3xl mx-auto p-5'>
            {post[0]?.image && (
                <img alt="Post" src={`${PROXY}/uploads/images/${post[0].image}`} className="bg-black w-full object-cover" />
            )}
            <div className='md:flex sm:flex-row'>
                <div className="w-2/3 sm:w-full p-3">
                    <div>
                        <h1 className="font-bold text-3xl mt-5">{post[0]?.title}</h1>
                        <small className="text-slate-400">{post[0]?.date} | Quilaton Law Office</small>
                    </div>
                    <p className="mt-3">{post[0]?.details}</p>
                    <p>{post[0]?.tags}</p>
                    <div className='mt-5'>
                        <p>Share & Follow us on Facebook:</p>
                       <div className='flex flex-row gap-2'>
                            <a 
                                href={`https://www.facebook.com/sharer/sharer.php?u=${pathname}`} target="_blank" rel="noopener noreferrer"
                                className='px-4 py-2 bg-blue-500 hover:bg-blue-300 transition-all duration-200 ease-in-out text-white rounded-xl text-sm'
                            >
                                Share on Facebook
                            </a>
                            <a 
                                href={`https://www.facebook.com/quilatonlawoffice`} target="_blank" rel="noopener noreferrer"
                                className='px-4 py-2 bg-blue-500 hover:bg-blue-300 transition-all duration-200 ease-in-out text-white rounded-xl text-sm'>
                                Follow @quilatonlawoffice
                            </a>
                       </div>
                    </div>
                </div>
                <div className='w-2/6 sm:w-full p-4 mt-5 bg-gray-200'>
                    <h2 className='text-xl italic'>Recent Posts</h2>
                    <div className='p-3'>
                        {recent.slice(0, 5).map((rec, index) => (
                            <div key={index} className='mt-2'>
                                <Link
                                    to={`/practices/posts/${formatTitleForURL(rec.title)}/`}
                                    className='flex text-sm'
                                >
                                    {rec.title}
                                </Link>
                                <p className='text-xs'>{rec.date}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='flex justify-between mt-10'>
                <button
                    onClick={() => window.location.href = `/practices/posts/${formatTitleForURL(previousPost?.title)}/`}
                    disabled={!previousPost}
                    className="hover:opacity-10 transition-all duration-300 ease-in-out"
                >
                    <img src={ArrowLeft} alt='ArrowLeft' className='h-6'/>
                    {previousPost ? `${previousPost.title}` : 'No Previous Post'}
                </button>
                <button
                    onClick={() => window.location.href = `/practices/posts/${formatTitleForURL(nextPost?.title)}/`}
                    disabled={!nextPost}
                    className="flex flex-col justify-end items-end hover:opacity-10 transition-all duration-300 ease-in-out"
                >
                    <img src={ArrowRight} alt='ArrowLeft' className='h-6'/>
                    {nextPost ? `${nextPost.title}` : 'No Next Post'}
                </button>
            </div>
        </div>
    );
}
