import { useState } from 'react';
import CustomMargin from "../../../components/custom-margin";
import axios from 'axios';
import AlertComponent from '../../../components/alert-component';
import { PROXY } from '../../../components/constants'

export default function SubmitTestimonial() {
    const [values, setValues] = useState({
        title: '',
        details: ''
    });
    const [hideFeedback, setHideFeedback] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        axios.post(`${PROXY}/submit_feedback`, values)
            .then(res => {
                setAlertMessage('Feedback Submitted Successfully!');
                setHideFeedback(true);
            })
            .catch(err => {
                console.error(err);
                setAlertMessage('Failed to submit feedback.');
            });
    };

    return (
        <CustomMargin>
            <form onSubmit={handleSubmit}>
                <div className={`flex flex-col w-1/2 sm:w-full lg:w-1/2 p-5 ${hideFeedback ? 'hidden' : ''}`}>
                    <h2 className="font-fontMontserrat text-4xl">We Value Your Feedback!</h2>
                    <p className="mt-5">Your feedback is incredibly important to us. Please rest assured that all feedback provided is completely anonymous and safe. Your honest opinions help us improve and serve you better.</p>
                    <input
                        placeholder="Title..."
                        className="border border-black p-2 mt-5"
                        onChange={(e) => setValues({ ...values, title: e.target.value })}
                        required
                    />
                    <textarea
                        placeholder="Share your feedback here..."
                        rows={5}
                        className="border border-black p-2 mt-5"
                        onChange={(e) => setValues({ ...values, details: e.target.value })}
                        required
                    >
                    </textarea>
                    <button
                        type="submit"
                        className="text-base py-5 px-7 mt-5 border border-black text-black hover:bg-black hover:text-white transition-all duration-300 ease-in-out"
                    >
                        SUBMIT
                    </button>
                </div>
            </form>
            {hideFeedback && (
                <AlertComponent message={alertMessage} />
            )}
        </CustomMargin>
    );
}
