import CustomMargin from "../../../components/custom-margin";
import { EMAIL_ADD, LOCATION_ADD, PHONE, PHONE_NUMBER} from '../../../components/constants'

export default function DetailsInfo(){
    return (
        <CustomMargin className="flex sm:flex-col w-4/5 mt-5">
            <div>
                <p className="font-fontMontserrat text-2xl">QUILATON LAW OFFICE</p>
                <p>{LOCATION_ADD}</p>
                <a href={`tel:${PHONE}`}>{PHONE_NUMBER}</a> <br></br>
                <a href={`mailto:${EMAIL_ADD}`}>{EMAIL_ADD}</a>
            </div>
            <div className="w-full h-72">
                <iframe 
                    title="Google Maps" 
                    className="filter grayscale" 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3931.311726963165!2d123.906992!3d10.3288842!2m3!1f0!2f19.634053199999997!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33a9998fef8428b1%3A0x931c003a8a5118d4!2sRegus%20-%20Cebu%2C%20Park%20Centrale!5e0!3m2!1sen!2sph!4v1626272171945!5m2!1sen!2sph" 
                    width="100%" 
                    height="100%" 
                    allowfullscreen="" 
                    loading="lazy">
                </iframe>
            </div>
        </CustomMargin>
    )
}