import TestimonialCard from "./components/testimonial-card";

export default function Feedback(){
    return(
        <div>
            <div>
                <TestimonialCard/>
            </div>
        </div>
    );
}